import React from "react";
import { AddMeterPayload } from "../api/meter";

type SyncMeterItemProprs = {
  meter: AddMeterPayload,
  onSyncClicked: (meter: AddMeterPayload) => void, 
  onDeleteClicked: (meter: AddMeterPayload) => void, 
}

export const SyncMeterItem: React.FC<SyncMeterItemProprs> = ({ meter, onDeleteClicked, onSyncClicked }) => {
  return (
    <div className="sync-meter-item">
      <div className="sync-meter-item__content">
        <div>
          <b>Rögzítés időpontja:</b>
          <span>{ meter.date }</span>
        </div>
        <div>
          <b>Rögzített mérőóra-állás</b>
          <span>{ meter.value || '-' }</span>
        </div>
        {
          meter.image &&
          <div>
            <b>Van csatolt kép</b>
        </div>
        }
      </div>
      <div className="sync-meter-item__buttons">
        <button className="button btn-sync" onClick={() => onSyncClicked(meter)}>Feltöltés</button>
        <button className="button btn-delete" onClick={() => onDeleteClicked(meter)}>Törlés</button>
      </div>
    </div>
  );
};
