import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthContext"

export const AuthCheckOutlet = () => {
  const { username } = useAuth();

  return username
          ? <Navigate to="/app"/>
          : <Navigate to="/login"/>

}

export const PrivateOutlet = () => {
  const { username } = useAuth();

  return username
          ? <Outlet/>
          : <Navigate to="/login"/>
}