import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { App } from './screens/App';
import { Login } from './screens/Login';
import { Header } from './components/Header';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { DBSelector } from './screens/DBSelector';
import { AddMeter } from './screens/AddMeter';
import { AuthProvider } from './auth/AuthContext';
import { AuthCheckOutlet, PrivateOutlet } from './auth/Outlets';
import { NetworkStateProvider } from './contexts/NetworkState';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SyncMeters } from './screens/SyncMeters';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <NetworkStateProvider>
      <AuthProvider>
          <div className="app">
            <Header />
            <Routes>
                <Route path="/" element={<AuthCheckOutlet />}/>
                <Route path="/app" element={<PrivateOutlet />}>
                  <Route path="" element={<Navigate to="db-selector"/>}/>
                  <Route path="db-selector" element={<DBSelector />}/>                  
                  <Route path=":dbId/tree" element={<App />}/>
                  <Route path=":dbId/add-meter/:placeId" element={<AddMeter />}/>
                  <Route path="sync-meters" element={<SyncMeters />}/>
                </Route>
                <Route path="login" element={<Login />}/>
            </Routes>
          </div>
          <ToastContainer></ToastContainer>
        </AuthProvider>
      </NetworkStateProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
