import React, { useEffect, useState } from 'react'
import { Place } from '../api/places';
import { TreeItem } from './TreeItem';

export interface TreeProps {
	list: Place[];
	onLeafClicked?: (leaf: any) => void;
};

export interface TreeItemProps extends TreeProps {
	item: any;
	level: number;
  openedItems: Set<number>
}

export const Tree: React.FC<TreeProps> = ({ list, onLeafClicked }) => {

  const [ openedItems, setOpenedItems ]  = useState(new Set<number>());

  useEffect(() => {

    setOpenedItems(
      new Set<number>(JSON.parse(sessionStorage.getItem('opened_items') || '[]'))
    );

  }, []);

  const onLeafClicked_middleware = (leaf: any) => {
    sessionStorage.setItem('opened_items', JSON.stringify(Array.from(openedItems)));
    if (typeof onLeafClicked === 'function') {
      onLeafClicked(leaf);
    }
  }

  return (
    <div>
      {
        list.filter(x => x.parent_id === 0).map(x => {
          return (
            <TreeItem
              item={x}
              list={list}
              key={x.place_id}
              level={0}
              onLeafClicked={onLeafClicked_middleware}
              openedItems={openedItems}/>
          );
        })
      }
    </div>
  );
}
