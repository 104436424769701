import { createContext, FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import loginRequest, { LoginResponse } from "../api/login";
import { clearUserDbs, saveUserDbs } from "../cache/dbs";
import { clearPlaces } from "../cache/places";
import { clearUsers, getLoggedInUser, saveLoggedInUser } from "../cache/users";

interface IAuthContext {
  username: string;
  password: string;
  dbs: Array<{ db_id: number, name: string }>
  logout: () => void;
  login: (username: string, password: string) => Promise<LoginResponse>;
}

export const AuthContext = createContext<Partial<IAuthContext>>({  });

export const useAuth = () => {
  return useContext(AuthContext);
}

export const AuthProvider: FC = ({ children }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [dbs, setDbs] = useState<Array<{ db_id: number, name: string }>>([]);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {

    setLoading(true);

    /**
     * kezdetben (AuthContext létrejöttekor) lekérem indxed db-ből az eltárolt user adatokat, ha vannak, 
     * és megpróbálok velük bejelentkezni (useEffect-ben nem lehet async/await-et használni)
     */
    getLoggedInUser()
      .then(user => {
        login(
          user?.username || '',
          user?.password || ''
        )
        .then(_ => {})
        .finally(() => setLoading(false))
      })
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     await clearUsers();
  //     if (username && password) {
  //       await saveLoggedInUser(username, password);
  //     }
  //   })().then();

  // }, [ username, password ]);

  const logout = async () => {
    /**
     * kijelentlezéskor minden eltárolt adatot törlök a böngészőből
     */
    await clearUsers();
    await clearUserDbs();
    await clearPlaces();
    sessionStorage.clear();
    setUsername('');
    setPassword('');
    navigate('/login');
  };

  const login = async (username: string, password: string): Promise<LoginResponse> => {
    //ha van net, akkor loginolok
    const resp = await loginRequest(username, password);

    if (resp.ok) {
      setUsername(username);
      setPassword(password);
      setDbs(resp.dbs!!);
    }

    return resp;
  }

  const context: IAuthContext = {
    username,
    password,
    dbs,
    logout,
    login
  };

  return (
    <AuthContext.Provider value={context}>
      { !loading && children }
    </AuthContext.Provider>
  );
};
