import { AddMeterPayload } from "../api/meter";
import { openDatabase } from "./db";

export const saveMeter = async (item: AddMeterPayload): Promise<boolean> => {
  const db = await openDatabase();

  if (!db) {
    return false;
  }

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['meters'], 'readwrite');
    transaction.onerror = (event) => {
      resolve(false);
    }
  
    transaction.oncomplete = (event) => {
      db.close();
    }
  
    const meterStore = transaction.objectStore('meters');

    const { password, ...rest } = item; //jelszót nem rakom el megint
    const request = meterStore.add(rest);
    request.onsuccess = (event) => {
      transaction.commit();
      resolve(true);
    }
  });
}

export const getMetersByUsername = async (username: string): Promise<AddMeterPayload[] | null> => {
  const db = await openDatabase();
  if (!db) {
    return null;
  }

  const meterStore = db.transaction(['meters'], 'readwrite')?.objectStore('meters');
  
  return new Promise((resolve, reject) => {
    const results: AddMeterPayload[] = [];

    const index = meterStore.index('username');

    index.openCursor(IDBKeyRange.only(username)).onsuccess = (event) => {
      const cursor = (event.target as any).result;

      if (cursor && cursor.key) {
        results.push({ ...cursor.value });     
        cursor.continue();
      } else {
        resolve(results);
      }
      
    }
  });
}

export const deleteMeterById = async (id: number): Promise<boolean> => {
  const db = await openDatabase();
  if (!db) {
    return false;
  }

  const request = db.transaction(['meters'], 'readwrite')?.objectStore('meters').delete(id);
  
  return new Promise((resolve, reject) => {
    request.onerror = () => {
      resolve(false);
    }

    request.onsuccess = () => {
      resolve(true);
    }
  });
}