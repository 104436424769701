import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import ArrowRight from '../images/arrow-right.svg';

export const DBSelector: React.FC = () => {
  const { dbs: items } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="db-selector">
      {
        items?.map(item => (
          <Link to={ `/app/${item.db_id}/tree` } className="db-selector__item" key={item.db_id}>
            <label>{ item.name }</label>
            <img src={ArrowRight} alt="Next arrow"/>
          </Link>
        ))
      }
    </div>
  );
}