import { Place } from '../api/places';
import { openDatabase } from './db';

export const savePlaces = async (places: Place[]): Promise<boolean> => {
  const db = await openDatabase();

  if (!db) {
    return false;
  }

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['places'], 'readwrite');
    transaction.onerror = (event) => {
      resolve(false);
    }
  
    transaction.oncomplete = (event) => {
      db.close();
    }
  
    const placeStore = transaction.objectStore('places');
    const promises = places.map(dbItem => new Promise((resolve) => {
      const request = placeStore.add(dbItem);
      request.onsuccess = (event) => {
        resolve(true);
      }

      request.onerror = (event) => {
        resolve(false);
      }
    }));

    Promise.all(promises).then(results => resolve(
      results.every(x => x === true)
    ));
  });
}

export const getPlacesByDbId = async (dbId: number): Promise<Place[] | null> => {
  const db = await openDatabase();
  if (!db) {
    return null;
  }

  const dbStore = db.transaction(['places'], 'readwrite')?.objectStore('places');
  
  return new Promise((resolve, reject) => {
    const results: Place[] = [];

    const index = dbStore.index('db_id');

    index.openCursor(IDBKeyRange.only(dbId)).onsuccess = (event) => {
      const cursor = (event.target as any).result;

      if (cursor && cursor.key) {
        results.push({ ...cursor.value });     
        cursor.continue();
      } else {
        resolve(results);
      }
      
    }
  });
}

export const clearPlaces = async (): Promise<boolean> => {
  const db = await openDatabase();
  if (!db) {
    return false;
  }

  const request = db.transaction(['places'], 'readwrite')?.objectStore('places').clear();
  
  return new Promise<boolean>((resolve, reject) => {
    request.onerror = (event) => {
      console.log('Error while clearing places', event);
      resolve(false);
    }
  
    request.onsuccess = (event) => {
      console.log('Places cleared');
      resolve(true);
    }
  });
}

export const clearPlacesByDbID = async (dbId: number): Promise<boolean> => {
  const db = await openDatabase();
  if (!db) {
    return false;
  }

  // https://stackoverflow.com/questions/18603993/deleting-multiple-records-in-indexeddb-based-on-index

  const placeStore = db.transaction(['places'], 'readwrite')?.objectStore('places');
  const index = placeStore.index('db_id');
  const request = index.openCursor(IDBKeyRange.only(dbId))
  
  return new Promise<boolean>((resolve, reject) => {
    request.onerror = (event) => {
      console.log('Error while clearing places', event);
      resolve(false);
    }
  
    request.onsuccess = (event: any) => {
      const cursor = event.target.result;
      if (cursor) {
        cursor.delete(cursor.primaryKey);
        cursor.continue();
      } else {
        resolve(true);
      }
    }
  });
}
