const DATABASE = 'e-kozuzem-pwa-indexed-db';
const DATABASE_VERSION = 1;

export const openDatabase = (): Promise<IDBDatabase | null> => {
  const request = indexedDB.open(DATABASE, DATABASE_VERSION);

  request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
    const _db = (event.target as any)?.result;

    //create stores
    const userStore = _db?.createObjectStore('users', {
      keyPath: 'username'
    });

    const dbStore = _db?.createObjectStore('dbs', {
      keyPath: 'db_id'
    });

    const placeStore = _db?.createObjectStore('places', {
      keyPath: 'place_id'
    });
    placeStore.createIndex('db_id', 'db_id', { unique: false });


    const meterStore = _db?.createObjectStore('meters', {
      keyPath: 'id',
      autoIncrement: true
    });

    /**
    * a mérés entryket nem fogom kitörölni kijlenetkezéskor,
    * hanem az indexed db-ben maradnak, ha esetleg valaki kijelentkezik véletlenül,
    * ne vesszen el az adat.
    * De ha több fiók is hasznalja az appot egy telefonról, akkor a szinkronizálás menüpontban
    * csak az aktuálisan belépett user által rögzített rekordokat listázom ki, tehát
    * kell egy index a username kulcsra a rekordokon.
    */
   meterStore.createIndex('username', 'username', { unique: false });

  }

  return new Promise((resolve, reject) => {
    request.onerror = (event) => {
      console.warn(`IndexedDB error: ${request.error}`);
      resolve(null);
    }
  
    request.onsuccess = (event) => {
      resolve(request.result);
    }
  });
}