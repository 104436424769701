import { useEffect, useState } from 'react';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import addMeter, { AddMeterPayload } from '../api/meter';
import { useAuth } from "../auth/AuthContext";
import { deleteMeterById, getMetersByUsername } from '../cache/meters';
import { SyncMeterItem } from '../components/SyncMeterItem';
import { useNetworkState } from '../contexts/NetworkState';
import { notifyError, notifySuccess, notifyWarning } from '../utils/toast';

type MeterItem = AddMeterPayload & { id: number };

export const SyncMeters: React.FC = () => {

  const [meters, setMeters] = useState<MeterItem[]>([]);
  const { username, password } = useAuth();
  const { isOnline } = useNetworkState();

  const handleDelete = async (meter: AddMeterPayload) => {
    if (!window.confirm('Biztosan törölni akarod a mérést?')) {
      return;
    }
    const meterId = (meter as MeterItem).id;

    if (meterId && (await deleteMeterById(meterId))) {
      notifySuccess('Mérés törölve!')
      fetchMeters();
    } else {
      notifyError('A mérést nem sikerült törölni!');
    }
  }

  const handleSync = async (meter: AddMeterPayload) => {
    if (!isOnline) {
      notifyWarning('Jelenleg nem érhető el hálózat, így nem lehet az adatokat felölteni!');
      return;
    }

    const meterPayload: AddMeterPayload = {
      ...meter,
      username: username!,
      password: password!,
    }
    const response = await addMeter(meterPayload);

    if (response.ok) {
      notifySuccess('A mérési adat sikeresen rögzítve a szerveren!');
      await deleteMeterById((meter as MeterItem).id);
      fetchMeters();
    } else {
      notifyError(response.error_msg);
    }
  }

  const fetchMeters = () => {
    getMetersByUsername(username!)
      .then(savedMeters => {
        if (!savedMeters) {
          notifyError('Nem sikerült kiolvasni a lokálisan elmentett mérőállásokat!');
          return;
        }

        setMeters(savedMeters as MeterItem[]);
      })
  }

  useEffect(() => {

    fetchMeters();

  }, []);

  return (
    <div className="sync-meters-screen">
      { meters.length > 0
       &&
       meters.map((meter) => {
        return (
          <SyncMeterItem
            key={meter.id}
            meter={meter}
            onDeleteClicked={handleDelete}
            onSyncClicked={handleSync}></SyncMeterItem>
        );
       })
      }

      { !meters.length
        &&
        <p className="empty-text">Jelenleg nincs szinkronizálásra váró mérés!</p>
      }
    </div>
  );
}

