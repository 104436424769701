import { openDatabase } from './db';

export const saveUserDbs = async (dbs: Array<{ db_id: number, name: string }>): Promise<boolean> => {
  const db = await openDatabase();

  if (!db) {
    return false;
  }

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['dbs'], 'readwrite');
    transaction.onerror = (event) => {
      resolve(false);
    }
  
    transaction.oncomplete = (event) => {
      db.close();
    }
  
    const userStore = transaction.objectStore('dbs');
    const promises = dbs.map(dbItem => new Promise((resolve) => {
      const request = userStore.add(dbItem);
      request.onsuccess = (event) => {
        resolve(true);
      }

      request.onerror = (event) => {
        resolve(false);
      }
    }));

    Promise.all(promises).then(results => resolve(
      results.every(x => x === true)
    ));
  });
}

export const getUserDbs = async (): Promise<Array<{ db_id: number, name: string }> | null> => {
  const db = await openDatabase();
  if (!db) {
    return null;
  }

  const request = db.transaction(['dbs'], 'readwrite')?.objectStore('dbs').getAll();
  
  return new Promise((resolve, reject) => {
    request.onerror = (event) => {
      resolve(null);
    }

    request.onsuccess = (event) => {
      resolve(request.result);
    }
  
  });
}

export const clearUserDbs = async (): Promise<boolean> => {
  const db = await openDatabase();
  if (!db) {
    return false;
  }

  const request = db.transaction(['dbs'], 'readwrite')?.objectStore('dbs').clear();
  
  return new Promise<boolean>((resolve, reject) => {
    request.onerror = (event) => {
      console.log('Error while clearing dbs', event);
      resolve(false);
    }
  
    request.onsuccess = (event) => {
      console.log('Dbs cleared');
      resolve(true);
    }
  });
}
