import { clearPlacesByDbID, getPlacesByDbId, savePlaces } from "../cache/places";
import toFormdata from "../utils/to-formdata";
import { BaseResponse, client } from "./http";

export interface PlacesResponse extends BaseResponse {
  places?: Place[];
  db_id?: number;
}

export interface Place {
  place_id: number,
  parent_id: number,
  leaf: boolean,
  name: string,
  meter_unit: string,
  gyszam: string,
  icon: string,
  db_id: number,
}

export default async function getPlaces(username: string, password: string, dbId: number): Promise<PlacesResponse> {
  if (!navigator.onLine) {
    console.log('Network offline, fetching places from cache');
    const places = await getPlacesByDbId(dbId);

    if (!places) {
      return {
        error_msg: '',
        ok: false
      };
    }

    return {
      error_msg: '',
      ok: true,
      places: places,
      db_id: dbId
    };
  }

  const response = (await client.post('/', toFormdata({ username, pw: password }), {
    params: {
      m: 'android',
      p: 'places',
      db_id: dbId
    },
  })).data;

  
  if (response.ok) {
    response.places = response.places.map((place: any) => ({ ...place, db_id: dbId }));
    await clearPlacesByDbID(dbId);
    await savePlaces(response.places);
    //place itemekbe beleírom a db_id-t, hogy indexed db-ben lehessen ez alapján keresni
  }

  return response;
}