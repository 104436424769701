import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import getPlaces, { Place } from '../api/places';
import { useAuth } from '../auth/AuthContext';
import { LoaderMask } from '../components/LoaderMask';
import { Tree } from '../components/Tree';

export const App: React.FC = () => {
  const navigate = useNavigate();
  const { dbId } = useParams();
  const { username, password } = useAuth();

  const [places, setPlaces] = useState<Place[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getPlaces(username!!, password!!, parseInt(dbId!!))
      .then(resp => {
        if (resp.ok && resp.places) {
          setPlaces(resp.places);
        }
      }).finally(() => setLoading(false))
  }, [])

  const onLeafClicked = (item: any) => {
    navigate(`/app/${dbId}/add-meter/${item.place_id}`);
  }

  return (
    <div className="app-screen">
      { loading && <LoaderMask /> }

      { places && <Tree list={places} onLeafClicked={onLeafClicked}/> }
    </div>
  );
}

