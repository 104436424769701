import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import { useNetworkState } from "../contexts/NetworkState";
import MenuIcon from '../images/menu-icon.svg';

export const Header: React.FC = () => {
  const { logout, username } = useAuth();
  const { isOnline } = useNetworkState();

  const navigate = useNavigate();

  const handleLogout = async () => {
    if (logout) await logout();
  }

  return (
    <header className="header">
      <img src="/images/e-kozuzem192.png" className="header__logo" alt="header logo"
        onClick={() => navigate('/app/db-selector')}/>

      <h1 className="header__title">E-közüzem Mobil</h1>

    {isOnline
      ? <span className="network-state online">online</span>
      : <span className="network-state offline">offline</span>
    }

      {username &&
      <div className="header__menu">
        <Menu menuButton={
          <MenuButton>
            <img src={MenuIcon} alt="logout icon" className="header__logout"/>
          </MenuButton>
        }>
          <MenuItem onClick={() => navigate('/app/sync-meters')}>Szinkronizálás</MenuItem>
          <MenuItem onClick={handleLogout}>Kijelentkezés</MenuItem>
        </Menu>
      </div>
      }
    </header>
  );
};
