import { toast } from "react-toastify"

const BASE_TOAST_OPTIONS: any = {
  position: 'bottom-center',
  autoClose: 3000,
  hideProgressBar: true,
  theme: 'light'
};

export const notifySuccess = (message: string) => {
  toast.success(message, BASE_TOAST_OPTIONS);
}

export const notifyError = (message: string) => {
  toast.error(message, BASE_TOAST_OPTIONS);
}

export const notifyWarning = (message: string) => {
  toast.warning(message, BASE_TOAST_OPTIONS);
}