import { useCallback, useEffect, useRef, useState } from "react";

interface FileAttachProps {
  accept?: string;
  onFileSelected: (file: File|null) => void;
};

export const FileAttach: React.FC<FileAttachProps> = ({ children, accept, onFileSelected }) => {
  const btn = useRef<HTMLButtonElement>(null);
  const input = useRef<HTMLInputElement>(null);

  const [labelText, setLabelText] = useState('');

  useEffect(() => {
    if (!input.current) return;

    input.current.onchange = (ev) => {
      const file = input.current?.files?.item(0);
      if (!file) {
        setLabelText('');
        onFileSelected(null);
      } else {
        setLabelText(file.name);
        onFileSelected(file);    
      }

    }

  }, [ ]);

  return (
    <div className="file-attach">
      <button ref={btn} onClick={() => {
        input.current?.click();
      }} className="file-attach__button button">{ children }</button>

      <label className="file-attach__label">{ labelText }</label>

      <input ref={input} style={{ display: 'none' }} type="file" accept={accept}/>
    </div>
  );
}