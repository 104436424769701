import React, { FormEvent, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../auth/AuthContext';
import { notifyError } from "../utils/toast";

export const Login: React.FC = () => {
  const username = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);
  const { login } = useAuth();
  const navigate = useNavigate();

  const onSubmit = async (ev: FormEvent) => {
    ev.preventDefault();

    if (!username.current?.value || !password.current?.value) {
      return;
    }

    if (login) {
      const resp = await login(username.current.value, password.current.value);

      if (resp.ok) {
        navigate('/app/db-selector');
      } else {
        notifyError(resp.error_msg)
      }
    }
  }

  return (
    <div className="login">
      <form className="login__form" onSubmit={(ev) => onSubmit(ev)}>
        <h1 className="login__title">Bejelentkezés</h1>

        <input
          ref={username}
          type="text"
          className="login__input input"
          placeholder="Felhasználónév"
        />
        <input
          ref={password}
          type="password"
          className="login__input input"
          placeholder="Jelszó"
        />

        <button className="button login__button">Bejelentkezés</button>
      </form>
    </div>
  );
};
