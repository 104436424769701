import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import addMeter, { AddMeterPayload, AddMeterResponse } from "../api/meter";
import { useAuth } from "../auth/AuthContext";
import { saveMeter } from "../cache/meters";
import { FileAttach } from "../components/FileAttach";
import { LoaderMask } from "../components/LoaderMask";
import { useNetworkState } from "../contexts/NetworkState";
import { convertToYMD, isYMD } from "../utils/dates";
import { notifyError, notifySuccess } from "../utils/toast";

export const AddMeter: React.FC = () => {
  const { placeId, dbId } = useParams();

  const { username, password } = useAuth();

  const [date, setDate] = useState<string>(convertToYMD(new Date()));
  const [image, setImage] = useState<File|null>(null);
  const [value, setValue] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const { isOnline } = useNetworkState();
  const navigate = useNavigate();

  const onImageUploaded = (image: File|null) => {

    setImage(image);
  }

  const handleSubmit = async () => {
    setLoading(true);

    const numericValue = parseFloat(value);
    const payload: AddMeterPayload = {
      username: username!!,
      password: password!!,
      date,
      value: numericValue,
      place_id: placeId!!,
      db_id: dbId!!,
      image
    };
    let resp: AddMeterResponse;
    
    const error = validatePayload(payload);
    if (error) {
      setLoading(false);
      notifyError(error);
      return;
    }


    if (isOnline) {
      resp = await addMeter(payload);
    } else {
      const result = await saveMeter(payload);
      resp = {
        ok: result,
        error_msg: result ? 'Hiba törént a lokális tárolás során!' : ''
      }
    }

    setLoading(false);
    if (resp.ok) {
      notifySuccess('Sikeres rögzítés!');
      navigate(`/app/${dbId}/tree`);
    } else {
      notifyError(resp.error_msg);
    }
  }

  const validatePayload = (payload: AddMeterPayload): string | null => {
    if (Number.isNaN(payload.value) || typeof payload.value !== 'number') {
      return 'A rögzített érték nem szám!'
    }

    if (!isYMD(payload.date)) {
      return 'A megadott dátum helytelen formátumú!';
    }

    if (payload.image && !(payload.image instanceof File)) {
      return 'A csatolt kép nem megfelelő!';
    }

    return null;
  }

  return (
    <div className="add-meter">
      { loading && <LoaderMask /> }


      <h2 className="add-meter__title">Mérőóra-állás rögzítése</h2>

      <label className="add-meter__label">Rögzítés időpontja*</label>
      <input className="add-meter__input input"
        placeholder="Rögzítés időpontja" type="date"
        value={date}
        onChange={(ev) => setDate(ev.target.value)}/>

      <label className="add-meter__label">Mérőóra állása*</label>
      <input className="add-meter__input input"
        placeholder="pl.: 1300.44 kWh" type="number"
        value={value}
        onChange={(ev) => setValue(ev.target.value)}/>

      <label className="add-meter__label">Fotó a mérőóráról</label>
      <FileAttach
        accept="image/png, image/jpeg" onFileSelected={onImageUploaded}>
          Fénykép feltöltése
      </FileAttach>

        <button className="add-meter__submit button" onClick={handleSubmit}>Küldés</button>
    </div>
  );
}