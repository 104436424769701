import { createContext, FC, useContext, useEffect, useState } from "react";
import { notifySuccess, notifyWarning } from "../utils/toast";

interface INetworkStateContext {
  isOnline: boolean,
};

const NetworkStateContext = createContext<INetworkStateContext>({ isOnline: navigator.onLine });

export const useNetworkState = () =>{
  return useContext(NetworkStateContext);
}

export const NetworkStateProvider: FC = ({ children }) => {

  const [ isOnline, setIsOnline ] = useState(false);

  const context: INetworkStateContext = {
    isOnline
  }

  useEffect(() => {
    setIsOnline(navigator.onLine);
    
    const onNetworkStateChange = () => {
      setIsOnline(navigator.onLine);

      if (navigator.onLine) {
        notifySuccess('Hálózat újra elérhető!');
      } else {
        notifyWarning('Hálózat nem elérhető!');
      }
    }

    window.addEventListener('online', onNetworkStateChange as any);
    window.addEventListener('offline', onNetworkStateChange as any);

    return () => {
      window.removeEventListener('online', onNetworkStateChange as any);
      window.removeEventListener('offline', onNetworkStateChange as any);
    }
  }, []);

  return (
    <NetworkStateContext.Provider value={context}>
      { children }
    </NetworkStateContext.Provider>
  );
}