import { useEffect, useState } from "react";
import ArrowRight from '../images/arrow-right.svg';
import { TreeItemProps } from "./Tree";

export const TreeItem: React.FC<TreeItemProps> = ({ item, list, level, onLeafClicked, openedItems }) => {

	const [items, setItems] = useState<any[]>([]);
	const [opened, setOpened] = useState(false);

	useEffect(() => {
		setItems(list.filter(x => x.parent_id === item.place_id));
		setOpened(openedItems.has(item.place_id));
	}, []);

	const getItemIcon = (): string => {
		const baseUrl = '/images/';
		if (item.icon) {
			return `${baseUrl}${item.icon}`;
		}

		if (item.leaf) {
			return `${baseUrl}table.png`;
		}

		return `${baseUrl}folder.png`;
	}

	const onItemClicked = () => {
		if (item.leaf && typeof onLeafClicked === 'function') {
			return onLeafClicked(item);
		}

		if (!item.leaf) {
			setOpened(!opened);
			saveItemState(!opened);
		}
	}

	const saveItemState = (state: boolean) => {

		if (state) {
			openedItems.add(item.place_id);
		} else {
			openedItems.delete(item.place_id);
		}
	}

	return (
		<div className="tree-item" key={`level-${level}`}>
			<div className="tree-item__header" onClick={onItemClicked}>
				{ items.length > 0 &&
					<img src={ArrowRight} className={ opened ? 'item-arrow opened' : 'item-arrow' } alt="Item arrow"/>
				}

				<img src={getItemIcon()} alt={item.icon}
					className="item-icon"/>
				<label className={ item.leaf ? 'leaf' : '' }>{ item.name }</label>
			</div>

			{ items.length > 0 && opened &&
				<div className="tree-item__children">

					{ items.map(x => {
						return (
							<TreeItem
								item={x}
								list={list}
								key={x.place_id}
								level={level + 1}
								onLeafClicked={onLeafClicked}
								openedItems={openedItems}/>
						);
					}) }
				</div>
			}

		</div>
	);
};
