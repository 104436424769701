import { clearUserDbs, getUserDbs, saveUserDbs } from "../cache/dbs";
import { clearUsers, getLoggedInUser, saveLoggedInUser } from "../cache/users";
import toFormdata from "../utils/to-formdata";
import { BaseResponse, client } from "./http";

export interface LoginResponse extends BaseResponse {
  dbs?: Array<{ db_id: number, name: string }>
}

export default async function login(username: string, password: string): Promise<LoginResponse> {

  //ha nincs net, akkor a cachből próbálom kiolvasni az adatot
  if (!navigator.onLine) {
    console.log('Network offline, logging in from cached data');
    const user = await getLoggedInUser();
    if (!user) {
      return { error_msg: 'Sikertelen bejelentkezés!', ok: false };
    }

    const dbs = await getUserDbs();
    return {
      error_msg: '',
      ok: true,
      dbs: dbs || []
    };
  }

  const response = (await client.post('/', toFormdata({ username, pw: password }), {
    params: {
      m: 'android',
      p: 'login'
    },
  })).data;


  //sikeres belépéskor elmentem indexed db-be a usert, és az adatbázisait
  //csak előbb kitörlök minden meglévőt, hogy ne legyen key conflict
  if (response.ok) {
    await clearUsers();
    await clearUserDbs();
    await saveLoggedInUser(username, password);
    await saveUserDbs(response.dbs);
  }

  return response;
}