import { openDatabase } from "./db";

export const saveLoggedInUser = async (username: string, password: string): Promise<boolean> => {
  const db = await openDatabase();

  if (!db) {
    return false;
  }

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['users'], 'readwrite');
    transaction.onerror = (event) => {
      resolve(false);
    }
  
    transaction.oncomplete = (event) => {
      db.close();
    }
  
    const userStore = transaction.objectStore('users');
    const request = userStore.add({ username, password });
    request.onsuccess = (event) => {
      transaction.commit();
      resolve(true);
    }
  });
}

export const clearUsers = async (): Promise<boolean> => {
  const db = await openDatabase();
  if (!db) {
    return false;
  }

  const request = db.transaction(['users'], 'readwrite')?.objectStore('users').clear();
  
  return new Promise<boolean>((resolve, reject) => {
    request.onerror = (event) => {
      console.log('Error while clearing users', event);
      resolve(false);
    }
  
    request.onsuccess = (event) => {
      console.log('Users cleared');
      resolve(true);
    }
  });
}

export const getLoggedInUser = async (): Promise<{username: string, password: string} | null> => {
  const db = await openDatabase();
  if (!db) {
    return null;
  }

  const userStore = db.transaction(['users'], 'readwrite')?.objectStore('users');
  
  return new Promise((resolve, reject) => {
    userStore.openCursor().onsuccess = (event) => {
      const cursor = (event.target as any).result;

      if (cursor && cursor.key) {
        return resolve({ ...cursor.value });
      }
      resolve(null);
    }
  });
} 