import toFormdata from "../utils/to-formdata";
import { BaseResponse, client } from "./http";

export interface AddMeterPayload {
  username: string;
  password: string;
  date: string;
  value: number;
  place_id: string|number;
  db_id: string|number;
  image: File|null
}

export interface AddMeterResponse extends BaseResponse {
  db_id?: number;
  place_id?: number;
  row?: {
    meter_id: number;
    place_id: string;
    date: number;
    value: string;
    meter_unit: string|null
  }
}

export default async function addMeter(data: AddMeterPayload): Promise<AddMeterResponse> {
  const formData = toFormdata({
    username: data.username,
    pw: data.password,
    date: data.date,
    value: data.value + ''
  });

  if (data.image) {
    formData.append('image', data.image);
  }

  return (await client.post('/', formData, {
    params: {
      m: 'android',
      p: 'newmeter',
      place_id: data.place_id,
      db_id: data.db_id
    }
  })).data;
}